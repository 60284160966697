import transition from "styled-transition-group";
import Flex from "app/components/Flex";
import media from "styled-media-query";
import styled from "styled-components";
import { LARGE_BREAKPOINT } from "constants/index";

export const FadeOutThumbnail = transition(Flex)`
  opacity: ${({ opacity }: { opacity: number }) => opacity};

  &:enter {
    opacity: 0;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

export const Wrapper = styled(Flex)`
  position: fixed;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 99999 !important;
  box-shadow: 0 -16px 10px -10px rgba(0, 0, 0, 0.15);
  transform: translateX(-50%);

  ${media.greaterThan(LARGE_BREAKPOINT)`
    justify-content: start;
    padding-left: 96px;
  `}
`;
