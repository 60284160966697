import { useAnimation } from "framer-motion";

export function useAnimate() {
  const animationControls = useAnimation();

  return {
    animationControls,
    animate: () => {
      animationControls.start({
        opacity: 0,
        transition: { duration: 0.3 },
      });
      animationControls.start({
        y: -600,
        transition: { duration: 4 },
      });
    },
  };
}
