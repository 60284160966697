import React from "react";
import { DATA_TEST_ID } from "constants/index";
import Button from "app/components/Button";
import { Wrapper } from "./styles";

interface Props {
  onSkipOrSelect(): void;
}

export function CategoriesOptionBar({ onSkipOrSelect }: Props) {
  return (
    <Wrapper left="49.5%" bottom={{ _: "61px", sm: "37px" }} width="100vw">
      <Button
        data-test-id={DATA_TEST_ID.onboarding.continue}
        width={{ _: "234px", xs: "314px" }}
        height="49px"
        my="12px"
        onClick={onSkipOrSelect}
      >
        Continue
      </Button>
    </Wrapper>
  );
}
