export const REGISTER_STEPS_MAP = {
  myself: [
    "accountType",
    "level",
    "reason",
    "categories",
    "email",
    "password",
    "checkout",
  ],
  "myself-and-family": [
    "accountType",
    "familyGoal",
    "level",
    "reason",
    "categories",
    "email",
    "password",
    "checkout",
  ],
  family: ["accountType", "familyGoal", "email", "password", "checkout"],
  default: [
    "accountType",
    "familyGoal",
    "level",
    "reason",
    "categories",
    "email",
    "password",
    "checkout",
  ],
};

export const FAMILY_DISABLED_REGISTER_STEPS = [
  "level",
  "reason",
  "categories",
  "email",
  "password",
  "checkout",
];

export const REASON_SLUG_MAP = {
  health: "health",
  social: "social",
  choreography: "choreography",
  style: "style",
};

export const CATEGORY_SLUG_MAP = {
  freeClasses: "free-classes",
  steezySweat: "steezy-sweat",
  hipHopPopularChoreography: "hip-hop-popular-choreography",
  danceWorkout: "dance-workout",
  contemporary: "contemporary",
  ballet: "ballet",
  kPop: "k-pop",
  heels: "heels",
  popping: "popping",
  jazz: "jazz",
  house: "house",
  liteFeet: "lite-feet",
  dancehall: "dancehall",
  locking: "locking",
  breaking: "breaking",
  krump: "krump",
  whacking: "whacking",
  tipsAndLectures: "tips-and-lectures",
  salsa: "salsa",
};

export const REASON_SLUG_TO_CATEGORIES_MAP = {
  health: "FITNESS",
  social: "PUBLIC",
  choreography: "CHOREO",
  style: "STYLE",
};

export const RESIZE_WIDTHS = {
  _: "238px",
  xs: "319px",
  sm: "402px",
  md: "502px",
  lg: "972px",
};

export const CATEGORIES_FILTERS = {
  onboarding: "onboarding",
};

export const ACCOUNT_TYPE_TO_ANALYTICS_VALUES_MAP = {
  myself: ["adult"],
  family: ["family"],
  "myself-and-family": ["adult", "family"],
};

export const FROM_MODULE = "Onboarding";
