import { gql, useMutation } from "@apollo/client";
import { FOLLOW_CATEGORIES_MUTATION } from "graphql/mutations";

export const useFollowCategoriesMutation = options => {
  const [followCategoriesMutation] = useMutation(FOLLOW_CATEGORIES_MUTATION, {
    ...options,
  });

  return ({ variables }) => {
    const { categories } = variables;
    return followCategoriesMutation({
      variables: {
        categories,
      },
      optimisticResponse: {
        __typename: "Mutation",
        followCategories: categories,
      },
      update: (cache, { data: { followCategories } }) => {
        followCategories.forEach(({ slug, isFollowing }) => {
          cache.writeFragment({
            id: `Category:{"slug":"${slug}"}`,
            fragment: gql`
              fragment MyCategory on Category {
                isFollowing
              }
            `,
            data: {
              isFollowing,
            },
          });
        });
      },
    });
  };
};
