import React, { useState } from "react";
import _ from "lodash";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { H5 } from "app/components/Typography";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import { useSelector } from "react-redux";
import Icon, { OnboardingCheck } from "app/components/Icon";
import { DATA_TEST_ID, IS_MOBILE } from "constants/index";
import { FadeOutThumbnail } from "./styles";
import { Category } from "./interfaces";

interface Props {
  category: Category;
  selectedCategorySlugs: string[];
  setSelectedCategorySlugs(a: string[]): void;
}

interface RootState {
  components: {
    Settings: {
      volume: number;
    };
  };
}

export function CategoryCard({
  category,
  selectedCategorySlugs,
  setSelectedCategorySlugs,
}: Props) {
  const settingsVolume = useSelector(
    ({ components }: RootState) => components.Settings.volume
  );
  const [isPreviewShowing, togglePreviewShowing] = useState(false);
  const [isCategorySelected, toggleCategorySelected] = useState(
    selectedCategorySlugs.includes(category.slug)
  );

  return (
    <Flex
      data-test-id={DATA_TEST_ID.onboarding.category}
      flexDirection="column"
      borderRadius="8px"
      position="relative"
      border="3px solid"
      borderColor={isCategorySelected ? "blue" : "transparent"}
      boxSizing="border-box"
    >
      {isCategorySelected && (
        <Icon
          data-test-id={DATA_TEST_ID.onboarding.categorySelectedIcon}
          as={OnboardingCheck}
          position="absolute"
          width="30px"
          height="30px"
          top="-10px"
          left="-10px"
          style={{ zIndex: 99 }}
        />
      )}
      <Flex
        position="relative"
        flexDirection="column"
        borderRadius="4px"
        height={{
          _: "252px",
          xs: "181px",
          sm: "231px",
          md: "184px",
        }}
        cursor="pointer"
        overflow="hidden"
        onMouseEnter={() => togglePreviewShowing(true)}
        onMouseLeave={() => togglePreviewShowing(false)}
        onClick={() => {
          if (!isCategorySelected) {
            setSelectedCategorySlugs(
              _.concat(selectedCategorySlugs, [category.slug])
            );
          } else {
            setSelectedCategorySlugs(
              _.without(selectedCategorySlugs, category.slug)
            );
          }
          toggleCategorySelected(!isCategorySelected);
        }}
      >
        <Flex
          position="relative"
          bg="black"
          overflow="hidden"
          justifyContent="center"
        >
          {!IS_MOBILE && isPreviewShowing && (
            <Flex
              position="absolute"
              justifyContent="center"
              width={{
                _: "150%",
                xs: "140%",
                sm: "160%",
                md: "140%",
                lg: "130%",
              }}
              height="auto"
            >
              <ResponsiveVideoPlayer
                playing
                playsinline
                controls={false}
                volume={1}
                muted={!settingsVolume}
                url={category.previewVideoUrl}
                loop
                showLoader={false}
                height="100%"
                width="auto"
                onReady={null}
                containerBg="black"
                objectFit={null}
                ChildControls={null}
              />
            </Flex>
          )}
          <FadeOutThumbnail
            justifyContent="center"
            timeout={1000}
            in={!IS_MOBILE && !isPreviewShowing}
            opacity={!IS_MOBILE && (isPreviewShowing ? 0 : 1)}
            bg="monochrome.8"
          >
            <Thumbnail
              height="100%"
              width="auto"
              src={`${category.previewThumbnailUrl}`}
              placeholderSrc={`${category.previewThumbnailUrl}?blur=200&px=16&auto=format`}
            />
          </FadeOutThumbnail>
        </Flex>
        <Flex
          flexDirection="column"
          p={3}
          color="monochrome.8"
          position="absolute"
          bg="#F9F6F2"
          textAlign="center"
          justifyContent="center"
          alignContent="center"
          width="100%"
          height="70px"
          bottom="0"
          backdropFilter="blur(15px)"
        >
          <H5>{category.name}</H5>
        </Flex>
      </Flex>
    </Flex>
  );
}
