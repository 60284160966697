import queryString from "query-string";

interface Input {
  stepsToIncrement: number;
  location: {
    search: string;
  };
  history: {
    push(uri: string): void;
  };
}

interface QueryObj {
  step?: number | string | string[];
}

export function incrementStep({ stepsToIncrement, location, history }: Input) {
  const query: QueryObj = queryString.parse(location.search);
  query.step = parseInt(query.step ?? "0", 10) + stepsToIncrement;
  const params = `?${queryString.stringify(query)}`;
  history.push(`/register${params}`);
  // Start loading the checkout and dashboard once we're progressing through registration
  if (query.step > 0) {
    // TODO (@tgercek): Address preloading with dynamic imports
    // Checkout.preload();
    // Dashboard.preload();
  }
}
