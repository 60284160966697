import React from "react";
import _ from "lodash";
import Flex from "app/components/Flex";
import Grid from "app/components/Grid";
import { useFollowCategoriesMutation } from "hooks/useFollowCategories";
import { incrementStep } from "app/routes/Register/helpers";
import { trackSelectCategoriesEventAction } from "modules/onboarding";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useAnimate } from "../useAnimate";
import { CategoryCard } from "./CategoryCard";
import { CategoriesWrapper } from "./CategoriesWrapper";
import { CategoriesOptionBar } from "./CategoriesOptionBar";
import { CATEGORY_SLUG_MAP, FROM_MODULE } from "../../constants";
import { Category } from "./interfaces";

interface Props {
  categories?: Category[];
  selectedCategorySlugs: string[];
  setSelectedCategorySlugs(): void;
  isClickDisabled: boolean;
  toggleIsClickDisabled(isClickDisabled: boolean): void;
}

export function Categories({
  categories = [],
  selectedCategorySlugs,
  setSelectedCategorySlugs,
  isClickDisabled,
  toggleIsClickDisabled,
}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { animationControls, animate } = useAnimate();

  const followCategories = useFollowCategoriesMutation({
    onCompleted: () => {
      dispatch(
        trackSelectCategoriesEventAction({
          selectedCategories: selectedCategorySlugs,
          module: FROM_MODULE,
        })
      );
      incrementStep({ stepsToIncrement: 1, location, history });
      toggleIsClickDisabled(false);
    },
  });

  return (
    <>
      <CategoriesWrapper animationControls={animationControls}>
        <Flex flexDirection="column" justifyContent="center" mt={3} mb={5}>
          <Grid
            justifyContent="center"
            gridGap={3}
            gridTemplateColumns={{
              _: "repeat(1, minMax(230px, 1fr))",
              xs: "repeat(2, minMax(142px, 1fr))",
              sm: "repeat(2, minMax(190px, 1fr))",
              md: "repeat(3, minMax(155px, 1fr))",
              lg: "repeat(5, minMax(165px, 1fr))",
              xl: "repeat(7, minMax(165px, 1fr))",
            }}
          >
            {categories &&
              _.map(
                _.orderBy(categories, "number_of_classes", "desc"),
                (category: Category) => (
                  <CategoryCard
                    key={category.slug}
                    selectedCategorySlugs={selectedCategorySlugs}
                    setSelectedCategorySlugs={setSelectedCategorySlugs}
                    category={category}
                  />
                )
              )}
          </Grid>
        </Flex>
      </CategoriesWrapper>
      <CategoriesOptionBar
        onSkipOrSelect={() => {
          if (isClickDisabled) {
            return;
          }
          toggleIsClickDisabled(true);

          animate();

          if (
            selectedCategorySlugs.includes(CATEGORY_SLUG_MAP.danceWorkout) &&
            !selectedCategorySlugs.includes(CATEGORY_SLUG_MAP.steezySweat)
          ) {
            selectedCategorySlugs.push(CATEGORY_SLUG_MAP.steezySweat);
          }

          const categoriesToFollow = selectedCategorySlugs.map(slug => ({
            slug,
            isFollowing: true,
          }));

          followCategories({
            variables: {
              categories: categoriesToFollow,
            },
          });
        }}
      />
    </>
  );
}
